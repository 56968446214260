<template>
  <div>
    <!-- picEB46D70CD0E1140A57EE7620544F368A.jpg -->
    <img class="rounded img-large" src="@/assets/news-network/brickkeeper-interview.jpg">
    <p>
      The LEGO Universe News Network recently met up with Professor Brickkeeper on a mysterious LEGO planet for this exclusive interview:
    </p>
    <p>
      <b>Professor Brickkeeper:</b> <i>Welcome to the Creation Lab! So glad you arrived safely! Oooops-- careful with that camera!  Watch your duff next to that bundle of blueprints…Wooops! Before you came in the odds were 88 to one that tower would topple, so when you started swinging around that big — but hold on a sec!! I’ve been looking for that pizzanator plan! Excellent! I’ll just have to pop out for some pepperoni in a bit!</i>
    </p>
    <p>
      <i>Make yourselves comfortable now. Cuppa’ tea?</i>
    </p>
    <p>
      <b>LEGO Universe News Network:</b> Thanks Brickkeeper! When did you last clean the Creation Lab?
    </p>
    <p>
      <b>Professor Brickkeeper:</b> <i> I fear that filling LEGO Universe with inspiration is keeping me far too busy to fix the flight-booster on my tidy-er-izer! I can get lost in the archives for hours just cataloging all the amazing creativity fans contribute to the game! Factor in the inventing I do for my secret My LEGO Network page and cleaning the Creation Lab is—let’s see here-- 98.78 percent out of the question! </i>
    </p>
    <p>
      <b>LEGO Universe News Network:</b> Do you ever get lonely?
    </p>
    <p>
      <b>Professor Brickkeeper: </b><i>Hmmmm…The Creation Lab is far too fun to feel like work, but I suppose it might be nice to hear the happy stomp of a little LEGO piece-o-saurus or the flippity-flap of a wee winged-flobot. Pets can be so— Oh, I say! Do you suppose that sludge there is tomato sauce? ... No?! On to the list it goes! ... Now where was I?</i>
    </p>
    <p>
      <b>LEGO Universe News Network: </b> You must be hungry Professor! One last question: What keeps you coming up with new creative challenges?
    </p>
    <p>
      <b>Professor Brickkeeper:</b><i> Don’t you know what’s happening on this planet? What do I mean?!?!-- It’s a scientific miracle you even made it here in one piece! The odds against it are gastronocomic-- I mean astronomical!</i>
    </p>
    <p>
      <i>Even though we’re still safe and snug as LEGO bugs here in the Creation Lab, forces are threatening the flow of fun in LEGO Universe! It’s simply preposterous!</i>
    </p>
    <p>
      <i>Now if you’ll pizza—I mean please allow me to see you out. Can you hand me that harmonic musket bender? … That’s a nun-chuck negator. Feel free to borrow that, but I need the one there next to the ninj-- watch that stack of storyboards!! ... Woops! ... Oh, hey! There’s the plan for my plastic-hair pufferizer!! Yes!!</i>
    </p>
    <p>
      . . .
    </p>
    <p>
      Help create a pet companion for the Brickkeeper. Take the
      <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '7E1F69A2-304C-4278-9DD4-C97B10FDB8F7'} }">Build Brickkeeper's pet</router-link>
      challenge  in the Creation Lab!
    </p>
  </div>
</template>
